import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { dehydrate, DehydratedState, QueryClient } from 'react-query';
import styled from 'styled-components';
import { CompanyHeader } from './CompanyHeader';
import CompanyTabs from './CompanyTabs';
import { NextPageFC } from 'types';
import { getCompany, getCompanySales } from '@http/endpoints/company';
import { MainLayout } from '@components/main-layout';
import { getUserTokenByContext } from '@shared/utils/getUserTokenByContext';
import { TopMenu } from '@components/main-layout/top-menu';
import { CompanyViewModel } from '@http/models/view-models/company';
import { useCompany } from '@http/hooks/company';
import { getCompanyReviews } from '@http/endpoints/reviews';

export interface CompanyScreenProps {
  company: CompanyViewModel;
  dehydratedState: DehydratedState;
}

const Wrapper = styled.div`
  margin-left: -${({ theme }) => theme.spaces.xs};
  margin-right: -${({ theme }) => theme.spaces.xs};
  margin-top: -66px;

  ${({ theme }) => theme.utils.media.moreThanMobile`
    margin-top: 0;
    margin-left: -${({ theme }) => theme.spaces.m};
    margin-right: -${({ theme }) => theme.spaces.m};
  `}
`;

const Company: NextPageFC<CompanyScreenProps> = ({ company: companyByServerSide }) => {
  const { data: company } = useCompany(companyByServerSide.id);

  return (
    <Wrapper>
      <CompanyHeader company={company || companyByServerSide} topMenu={<TopMenu title="" colorTheme="light" />} />
      <CompanyTabs company={company || companyByServerSide} />
    </Wrapper>
  );
};

Company.layout = ({ children }) =>
  MainLayout({
    children,
    topMenuProps: {
      title: '',
      isMenuHidden: true,
    },
  });

Company.headProps = ({ company }) => ({
  title: `${company?.title} / Утилизатор на Kritod.ru`,
  description: `${company?.title} / Справочник утилизаторов на Kritod.ru`,
});

export const getCompanySsr = async (slug: any, ctx: GetServerSidePropsContext) => {
  const queryClient = new QueryClient();
  const token = getUserTokenByContext(ctx);
  const defaultReviewsPage = 1;

  const [company] = await Promise.all([
    queryClient.fetchQuery(['company-full', slug], () => getCompany(slug, token)),
    queryClient.prefetchQuery(['company-sales', slug], () => getCompanySales({ companyId: slug })),
    queryClient.prefetchQuery(['company-reviews', slug, defaultReviewsPage], () =>
      getCompanyReviews({ companyId: slug, isVisible: true })
    ),
  ]);

  return {
    props: {
      company,
      dehydratedState: dehydrate(queryClient),
    },
  };
};

export const getServerSideProps: GetServerSideProps<CompanyScreenProps, { id: string }> = async ctx => {
  const companyId = Number(ctx.params?.id);
  if (isNaN(companyId)) {
    return {
      notFound: true,
    };
  }

  try {
    return getCompanySsr(companyId, ctx);
  } catch (err) {
    if (err.isAxiosError && err.response.status === 404) {
      return {
        notFound: true,
      };
    }

    throw err;
  }
};

export default Company;
