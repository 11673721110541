import { GetServerSideProps } from 'next';
import { NotFoundScreen } from '../screens/NotFound';
import { NextPageFC } from '../types';
import { MainLayout } from '@components/main-layout';
import CompanyScreen, { CompanyScreenProps, getCompanySsr } from 'screens/Company';

const Page: NextPageFC<CompanyScreenProps> = ({ company, dehydratedState }) => {
  if (company) {
    return <CompanyScreen company={company} dehydratedState={dehydratedState!} />;
  }
  return <NotFoundScreen />;
};

Page.layout = props => {
  if (props.pageProps?.company && CompanyScreen.layout) {
    return CompanyScreen.layout(props as any);
  }
  return MainLayout({ children: props.children, topMenuProps: { title: ' ' } });
};

Page.headProps = props => {
  if (props.company && CompanyScreen.headProps && typeof CompanyScreen.headProps === 'function') {
    return CompanyScreen.headProps(props as any);
  }
  return {
    title: 'Kritod.ru - Раздел не существует',
  };
};

const isFile = (name: string) => /\.w+$/g.test(name);

export const getServerSideProps: GetServerSideProps<CompanyScreenProps, { rest: string[] }> = async ctx => {
  const params = ctx.params?.rest;

  if (params && params.length > 0) {
    try {
      const slug = encodeURIComponent(params[0]);
      if (!isFile(slug)) {
        const res = await getCompanySsr(slug, ctx);
        return res;
      }
    } catch {
      // silence
    }
  }

  ctx.res.statusCode = 404;
  return {
    props: {} as any,
  };
};

export default Page;
